:root {
    --text-color: rgba(0, 0, 0, 0.85);
}

html {
    color: var(--text-color);
    background-color: rgb(20, 20, 20);
}

body {
    font-family: sans-serif;
    padding: 20px;
}

* {
    margin: 0;
    padding: 0;
}


@media (max-width: 992px) {
    .ant-picker-panel:last-child .ant-picker-date-panel {
        display: none !important;
    }

    .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-next-btn,
    .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-super-next-btn {
        visibility: initial !important;
    }

    .ant-picker-panel.ant-picker-range-wrapper {
        min-width: 288px !important;
    }

    .ant-picker-presets {
        display: none !important;
    }
}
